<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>code </label>
          <input v-model="form.code" :class="errors.code?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.code" class="invalid-feedback">
            <template v-for=" error in errors.code"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>libelle </label>
          <input v-model="form.libelle" :class="errors.libelle?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.libelle" class="invalid-feedback">
            <template v-for=" error in errors.libelle"> {{ error[0] }}</template>

          </div>
        </div>


        <!-- <div class="form-group">
            <label>identifiants_sadge </label>
            <input type="text" :class="errors.identifiants_sadge?'form-control is-invalid':'form-control'"
                   v-model="form.identifiants_sadge">

            <div class="invalid-feedback" v-if="errors.identifiants_sadge">
                <template v-for=" error in errors.identifiants_sadge"> {{ error[0] }}</template>

            </div>
        </div>


        <div class="form-group">
            <label>creat_by </label>
            <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                   v-model="form.creat_by">

            <div class="invalid-feedback" v-if="errors.creat_by">
                <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

            </div>
        </div> -->


        <div class="form-group">
          <label>services </label>
          <CustomSelect
              :key="form.service"
              :columnDefs="['id','libelle']"
              :oldValue="form.service"
              :renderCallBack="(data)=>`${data.libelle}`"
              :selectCallBack="(data)=>form.service_id=data.id"
              :url="`${axios.defaults.baseURL}/api/services-Aggrid1`"
              filter-key=""
              filter-value=""
          />
          <div v-if="errors.service_id" class="invalid-feedback">
            <template v-for=" error in errors.service_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button class="btn btn-primary" type="submit">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>


import Files from "@/components/Files.vue"


export default {
  name: 'CreateFonctions',
  components: {VSelect: () => import("vue-select"), CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'servicesData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        code: "",

        libelle: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        service_id: "",

        deleted_at: "",

        identifiants_sadge: "",

        creat_by: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/fonctions', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        code: "",
        libelle: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        service_id: "",
        deleted_at: "",
        identifiants_sadge: "",
        creat_by: "",
      }
    }
  }
}
</script>
